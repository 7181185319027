import React from "react"
import ReactMarkdown from "react-markdown"
import styles from "./equipementContain.module.css"

export default (props) => (
  <div className={styles.equipementContain}>
    <div className={styles.titles}>
      <h3>{props.title}</h3>
      <h5>{props.subtitle}</h5>
    </div>
    <div className={styles.contain}>
      <div className={styles.column}>
        <ReactMarkdown source={props.column1} />
      </div>
      <div className={styles.column}>
        <ReactMarkdown source={props.column2} />
      </div>
      <div className={styles.column}>
        <ReactMarkdown source={props.column3} />
      </div>
    </div>
  </div>
)
import React from 'react';
import Img from 'gatsby-image';
import SlickSlider from 'react-slick';
import Arrow from './assets/arrow.jpg';
import './Slider.css';

class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state = {
      sliderHeight: '60vh',
    };
  }

  componentDidMount() {
    this.setState({
      sliderHeight: window.innerWidth < 1000 ? '30vh' : '60vh',
    });
  }

  componentDidUpdate() {

  }

  next() {
    this.slider.slickNext();
  }

  previous() {
    this.slider.slickPrev();
  }

  render() {
    const { children, photos } = this.props;
    const settings = {
      infinite: true,
      slidesToScroll: 1,
      variableWidth: true,
      arrows: false,
    };
    const { sliderHeight } = this.state;
    const slides = photos.map(photo => (
      <div>
        <Img
          key={photo.id}
          fluid={photo.fluid}
          className="partners_logo"
          style={{ width: `calc((${photo.width} / ${photo.height}) * (${sliderHeight}))` }}
        />
      </div>

    ));

    return (
      <div>
        <SlickSlider ref={(c) => { this.slider = c; }} {...settings}>
          {slides}
        </SlickSlider>

      </div>

    );
  }
}

export default Slider;

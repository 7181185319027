import React from "react"
import { ImageDisplay } from "utils"
import ReactMarkdown from "react-markdown"
import wifiZone from './assets/wifiZone.jpg'
import styles from "./simpleArticle.module.css"

export default (props) => (
  <div className={props.theme === 0 ? styles.simpleArticleWhite : styles.simpleArticlePink}>
    <div className={styles.titles}>
      <h3>{props.title}</h3>
      <h5>{props.subtitle}</h5>
    </div>

    <div className={styles.contain}>
      <div className={styles.left}>
        <ImageDisplay img={props.img} />
      </div>
      <div className={styles.right}>
        <ReactMarkdown source={props.text} />
      </div>
    </div>
    <div className={styles.wifiZone}>
      {props.isWifi && <img src={wifiZone} alt="wifiZone" />}
    </div>
  </div>
)